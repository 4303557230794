import * as React from 'react'
import { Dispatch, FC, SetStateAction, useEffect } from 'react'
import { useMount } from 'react-use'
import { compact } from 'lodash'
import { useGetEmployeeCourseStatsQuery } from '@services/statistic'
import { useDeleteUserMutation, useGetUserQuery } from '@services/users'
import { AccordionBodyProps } from '@organisms/Accordion'
import Tabs from '@organisms/Tabs'
import StatisticButton from '@templates/StatisticButton'
import EditModal from '@templates/EditModal'
import EmployeeModal from '@modals/EmployeeModal'
import DeleteButton from '@templates/DeleteButton'
import RiskMapsBodyList from '@templates/RiskMapsBodyList'
import { createInitFilter } from '@utils/filters'
import { StyledFooter } from './styled'
import PersonalInformation from './PersonalInformation'
import Study from './Study'
import Sout from './Sout'

type Props = {
  id: number
  active: number
  setActive: Dispatch<SetStateAction<number>>
} & AccordionBodyProps

const Body: FC<Props> = ({
  id,
  onChangeSize,
  active,
  setActive,
  toggleLoading,
}) => {
  const [deleteUser, deleteData] = useDeleteUserMutation()
  const { data, isSuccess: dataIsSuccess } = useGetUserQuery(id)
  const { data: statsData, isSuccess: statsIsSuccess } =
    useGetEmployeeCourseStatsQuery(data?.id as number, { skip: !dataIsSuccess })
  const { items: stats = [] } = statsData || {}

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (statsIsSuccess) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [statsIsSuccess])

  const handleTabChange = () => {
    setTimeout(onChangeSize, 500)
  }

  if (!data) return null

  return (
    <>
      <Tabs
        active={active}
        setActive={setActive}
        onTabChange={handleTabChange}
        tabSize="small"
        items={compact([
          {
            id: 'personal',
            title: 'Личные данные',
            children: <PersonalInformation user={data} />,
          },
          stats.length > 0 && {
            id: 'study',
            title: 'Обучение',
            children: <Study stats={stats} onChangeSize={onChangeSize} />,
          },
          window.ENABLE_SOUT && {
            title: 'СОУТ',
            id: 'sout',
            children: <Sout />,
          },
          {
            id: 'riskMaps',
            title: 'Карты рисков',
            children: (
              <RiskMapsBodyList
                initFilters={createInitFilter('forEmployee', data?.['@id'])}
              />
            ),
          },
        ])}
      />
      <StyledFooter>
        <EditModal
          ModalContent={EmployeeModal}
          modalProps={{ employee: data }}
        />
        <StatisticButton user={data} stats={stats} />
        <DeleteButton {...deleteData} onClick={() => deleteUser(data.id)} />
      </StyledFooter>
    </>
  )
}

export default Body
