import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.label<{
  widthMultiplier: number
}>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 10px;
  width: ${({ widthMultiplier = 1 }) =>
    isMobile
      ? '85vw'
      : `${
          widthMultiplier > 1
            ? 14 * widthMultiplier + widthMultiplier * 260
            : widthMultiplier * 260
        }px`};
`

export const Label = styled.div`
  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
`

export const FieldWrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: flex-start;
`

export const DesktopLabelWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  height: 30px;
`

export const MobileLabelWrapper = styled.div`
  display: flex;
`

export const LabelWrapper = isMobile ? MobileLabelWrapper : DesktopLabelWrapper

export const PromptWrapper = styled.div`
  position: relative;
`

export const InlineLabelWrapper = styled(Wrapper)`
  display: flex;
  gap: 10px;
  height: 50px;
  align-items: center;
  flex-direction: row;
`

export const RightLabel = styled(Label)`
  display: flex;
  align-items: center;
`
