import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react'
import { H1, H1Small, H2, H3, H4 } from './styled'

type Sizes = 'h1' | 'h2' | 'h3' | 'h4' | 'h1small'

const COMPONENTS_MAP = new Map<Sizes, FC>([
  ['h1', H1],
  ['h2', H2],
  ['h3', H3],
  ['h4', H4],
  ['h1small', H1Small],
])

type Props = {
  mySize?: Sizes
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Title: FC<Props> = ({ mySize, children, ...rest }) => {
  const Component = (mySize && COMPONENTS_MAP.get(mySize)) || H1

  return <Component {...rest}>{children}</Component>
}

export default Title
