import React, { FC } from 'react'
import useWorkPlaces from '@hooks/useWorkPlaces'
import ListPageLayout from '@templates/ListPageLayout'
import WorkPlacesTable from '@pages/WorkPlaces/WorkPlacesTable'

const WorkPlacesReview: FC = () => {
  const { data, ...rest } = useWorkPlaces()
  const { items = [], length = 0 } = data || {}

  return (
    <ListPageLayout
      {...rest}
      titleTabs={[
        {
          title: 'Рабочие места',
          counter: length,
          index: 0,
        },
      ]}
      ListComponent={WorkPlacesTable}
      list={items}
      length={length}
      withSearch
    />
  )
}

export default WorkPlacesReview
