export enum Routes {
  INIT = '/',
  AUTH = '/auth',
  CERTIFICATES = '/certificates',
  COURSES = '/courses',
  COURSE = '/courses/:courseId/education/:educationId',
  CURATOR = '/curator',
  LIBRARY = '/library',
  PROFILE = '/profile',
  SETTINGS = '/settings',
  STATISTIC = '/statistic',
  STUDY = '/study',
  TARIFF = '/tariff',
  ENROLLMENT_HISTORY = '/enrollment-history',
  THEMES = '/themes',
  THEME = '/themes/:themeId/course/:courseId/education/:educationId',
  PRIVACY_POLICY = '/privacy-policy',
  POSITIONS = '/positions',
  DIVISIONS = '/divisions',
  FILIALS = '/filials',
  COMPANY_GROUPS = '/company-groups',
  COMPANIES = '/companies',
  EMPLOYEES = '/employees',
  EMPLOYEES_EDUCATION = '/employees-education',
  EDUCATION_CENTERS = '/education-centers',
  EDUCATION_CENTERS_LEARNING = '/education-centers-learning',
  GUIDES = '/guide',
  GUIDE = '/guide/:code',
  FILIAL_SETTINGS = '/filial-settings',
  DIVISION_SETTINGS = '/division-settings',
  COMPANY_MODAL = '/company-modal',
  FILIAL_MODAL = '/filial-modal',
  DIVISION_MODAL = '/division-modal',
  POSITION_MODAL = '/position-modal',
  EMPLOYEE_MODAL = '/employee-modal',
  EDUCATION_MODAL = '/education-modal',
  COMPANY_SETTINGS = '/company-settings',
  RISKS_MAPS = '/risk-maps',
  RISKS_MAPS_REVIEW = '/risk-maps-review',
  MEDICAL_REPORT = '/medical-report',
  WORK_PLACES = '/work-places',
  WORK_PLACES_REVIEW = '/work-places-review',
}
