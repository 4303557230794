import { useState, useCallback } from 'react'

const useLocalStorage = (
  name: string,
  defaultValue = ''
): [string | null, (newValue: string) => void] => {
  const [value, setValue] = useState<string | null>(() => {
    const cookie = localStorage.getItem(name)
    if (cookie) return cookie
    localStorage.setItem(name, defaultValue)
    return defaultValue
  })

  const updateLocalStorage = useCallback(
    (newValue: string) => {
      localStorage.setItem(name, newValue)
      setValue(newValue)
    },
    [name]
  )

  return [value, updateLocalStorage]
}

export default useLocalStorage
