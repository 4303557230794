import styled from '@emotion/styled'
import { isMobile } from '@const/device'

const SMALL_SIZE = 20
const BIG_SIZE = 35

const getSize = ({ big }: { big?: boolean }) => (big ? BIG_SIZE : SMALL_SIZE)

export const StyledCheckbox = styled.input<{
  big?: boolean
}>`
  border: 1px solid ${({ theme }) => theme.colors.disable};
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;

  min-height: ${getSize}px;
  max-height: ${getSize}px;
  min-width: ${getSize}px;
  max-width: ${getSize}px;
  height: ${getSize}px;
  width: ${getSize}px;

  accent-color: ${({ theme }) => theme.colors.main};
`

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  cursor: pointer;
  user-select: none;
`

export const TextWrapper = styled.div`
  white-space: ${() => (isMobile ? 'pre-wrap' : 'nowrap')};
`
