import { compact } from 'lodash'
import { FC, useEffect, useState } from 'react'
import Pagination from '@templates/Pagination'
import { PER_PAGE } from '@const/pagination'
import useSearchParam from '@hooks/useSearchParam'
import { ListPageLayoutListProps } from '@templates/ListPageLayout'
import EmptyList from './EmptyList'
import { ItemsWrapper, ListWrapper, Wrapper } from './styled'

export type ListItemProps<Item, Additional = any> = {
  item: Item
  index: number
  isLoading: boolean
  additionalPropsForItem: Additional
  handleClickOnItem: () => void
  opened: boolean
}

type Props = ListPageLayoutListProps

const List: FC<Props> = ({
  ItemComponent,
  list,
  perPage = PER_PAGE,
  perLine = 1,
  isLoading = false,
  length,
  setPage,
  additionalPropsForItem,
  onRefresh,
  scrollToElementId,
}) => {
  const openedQuery = useSearchParam('opened')
  const initialOpened = openedQuery ? [+openedQuery] : []
  const [opened, setOpened] = useState<number[]>(initialOpened)

  useEffect(() => {
    if (isLoading) {
      setOpened(initialOpened)
    }
  }, [isLoading])

  const handleClickOnItem = (index: number) => {
    setOpened((prev) => {
      const findedIndex = prev.indexOf(index)
      if (findedIndex > -1) {
        delete prev[findedIndex]
        return compact(prev)
      } else {
        return [...prev, index]
      }
    })
  }

  const handleChangePagination = (page: number) => {
    setOpened([])
    setPage?.(page)
  }

  let arrayIndex = 0

  const loadingList: Record<string, unknown>[] = []
  for (let i = 0; i < perPage * perLine; i++) {
    loadingList.push({})
  }

  const resultList = isLoading ? loadingList : list

  const perLinesFiltered: any[][] = resultList.reduce(
    (acc, item, index) => {
      if (index > 0 && index % perLine === 0) {
        arrayIndex = arrayIndex + 1
        acc[arrayIndex] = []
      }

      acc[arrayIndex].push(item)
      return acc
    },
    [[]] as any[][]
  )

  if (
    !isLoading &&
    (!perLinesFiltered ||
      perLinesFiltered.length === 0 ||
      perLinesFiltered[0]?.length === 0)
  ) {
    return <EmptyList onRefresh={onRefresh} />
  }

  if (!ItemComponent) {
    return 'Не задан компонент списка'
  }

  return (
    <Wrapper id="list">
      <ListWrapper>
        {perLinesFiltered.map((line, i) => (
          <ItemsWrapper key={i + 1}>
            {line.map((item, index) => {
              const hydraId =
                typeof item['@id'] === 'string' ? item['@id'] : undefined
              const idFromHydra =
                typeof hydraId?.split('/')?.at(-1) === 'string'
                  ? Number(hydraId?.split('/')?.at(-1))
                  : undefined

              const resultKey =
                (item.id as number) || idFromHydra || (index + 1) * (i + 1)

              return (
                <ItemComponent
                  item={item}
                  index={i}
                  key={resultKey}
                  isLoading={isLoading}
                  additionalPropsForItem={additionalPropsForItem}
                  handleClickOnItem={() => handleClickOnItem(resultKey)}
                  opened={opened.includes(resultKey)}
                />
              )
            })}
          </ItemsWrapper>
        ))}
      </ListWrapper>
      <Pagination
        itemsCount={length}
        perPage={perPage}
        onChangePagination={handleChangePagination}
        scrollToElementId={scrollToElementId}
      />
    </Wrapper>
  )
}

export default List
