import { FC } from 'react'
import AddNewItemModalLayout, {
  AddNewItemModalLayoutProps,
} from '@templates/AddNewItemModalLayout'
import Onboarding, { OnboardingProps } from '@templates/Onboarding'
import { FieldProps } from '../Field'
import Content, { ContentProps } from './Content'

export enum FromButtons {
  MAIN,
  ADD_MORE,
  NEXT,
}

type Field = FieldProps

type Props = AddNewItemModalLayoutProps &
  ContentProps & { onboarding?: OnboardingProps }

/**
 * @deprecated use AddNewItemModalLayout
 */
const AddNewItemModal: FC<Props> = ({
  fields,
  contentAfterFields,
  contentBeforeFields,
  onboarding,
  isEdit,
  ...props
}) => {
  return (
    <AddNewItemModalLayout {...props} isEdit={isEdit}>
      {onboarding && !isEdit && <Onboarding {...onboarding} />}
      <Content
        fields={fields}
        contentAfterFields={contentAfterFields}
        contentBeforeFields={contentBeforeFields}
      />
    </AddNewItemModalLayout>
  )
}

export type FieldParams = Omit<Field, 'Component'> & {
  isRequired?: boolean
  big?: boolean
  isDisabled?: boolean
  isLoading?: boolean
}
export type { Field as FieldForRender }
export default AddNewItemModal
