import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { compact } from 'lodash'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { Field } from '@templates/AddNewItemModal/Content'
import {
  checkboxField,
  dateField,
  fileField,
  numberField,
  textAreaField,
  textField,
} from '@templates/AddNewItemModal/templates'
import useJobFields from '@hooks/useJobFields'
import { MainFields } from '@modals/JobConditionAssessmentModal/enums'

const WIDTH_MULTIPLIER = 4

const Main: FC = () => {
  const methods = useFormContext()
  const { company, filial, division, position } = useJobFields(methods)

  const line1: Field[] = [
    textField({
      label: 'Номер карты',
      name: MainFields.CARD_NUMBER,
      placeholder: 'Номер карты',
      isRequired: true,
    }),
    dateField({
      label: 'Дата карты',
      name: MainFields.DATE,
      isRequired: true,
    }),
    fileField({
      name: MainFields.FILE,
      label: 'Файл карты',
      maxSizeMegaBytes: 20,
      formats: ['xls', 'xlsx', 'pdf', 'doc', 'docx', 'png', 'jpg', 'jpeg'],
    }),
  ]

  const line2: Field[] = compact([
    company?.(),
    filial?.(),
    division?.(),
    position?.(),
  ])

  const line3: Field[] = [
    textField({
      label: 'Номер рабочего места',
      name: MainFields.WORK_PLACE_NUMBER,
      placeholder: 'Номер рабочего места',
      isRequired: true,
    }),
    textField({
      label: 'Наименование профессии (должности) работника',
      name: MainFields.TITLE,
      placeholder: 'Наименование профессии (должности) работника',
    }),
    textField({
      label: 'Код профессии (должности)',
      name: MainFields.CODE,
      placeholder: 'Код профессии (должности)',
    }),
    textField({
      label: 'Количество и номера аналогичных рабочих мест',
      name: MainFields.SIMILAR_WORKPLACES_COUNT_AND_NUMBERS,
      placeholder: 'Количество и номера аналогичных рабочих мест',
    }),
  ]

  const line4: Field[] = [
    checkboxField({
      name: MainFields.DECLARED,
      label: 'Декларируемое рабочее место',
    }),
  ]

  const line5: Field[] = [
    textAreaField({
      name: MainFields.EKTS_EDITION,
      placeholder: 'Выпуск ЕТКС, ЕКС',
      label: 'Выпуск ЕТКС, ЕКС',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line6: Field[] = [
    textAreaField({
      name: MainFields.USED_PRODUCTION_EQUIPMENT,
      placeholder: 'Используемое производственное оборудование',
      label: 'Используемое производственное оборудование',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line7: Field[] = [
    textAreaField({
      name: MainFields.USED_MATERIALS_AND_RAW,
      placeholder: 'Используемые материалы и сырье',
      label: 'Используемые материалы и сырье',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line8: Field[] = [
    textAreaField({
      name: MainFields.WORK_IMPROVEMENT_RECOMENDATIONS,
      placeholder: 'Рекомендации по улучшению условий труда',
      label: 'Рекомендации по улучшению условий труда',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line9: Field[] = [
    textAreaField({
      name: MainFields.WORK_REST_RECOMENDATIONS,
      placeholder: 'Рекомендации по режимам труда и отдыха',
      label: 'Рекомендации по режимам труда и отдыха',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line10: Field[] = [
    textAreaField({
      name: MainFields.WORKER_APPLY_RECOMENDATIONS,
      placeholder: 'Рекомендации по подбору работников',
      label: 'Рекомендации по подбору работников',
      widthMultiplier: WIDTH_MULTIPLIER,
    }),
  ]

  const line11: Field[] = [
    numberField({
      name: MainFields.ON_WORK_PLACE,
      label: 'На рабочем месте',
      placeholder: 'На рабочем месте',
    }),
    numberField({
      name: MainFields.ON_SIMILAR_WORK_PLACE,
      label: 'На всех аналогичных местах',
      placeholder: 'На всех аналогичных местах',
    }),
    numberField({
      name: MainFields.WOMEN,
      label: 'Из них женщин',
      placeholder: 'Из них женщин',
    }),
    numberField({
      name: MainFields.UNDER_18,
      label: 'Из них лиц до 18 лет',
      placeholder: 'Из них лиц до 18 лет',
    }),
  ]

  const line12: Field[] = [
    numberField({
      name: MainFields.DISABLED,
      label: 'Из них инвалидов',
      placeholder: 'Из них инвалидов',
    }),
  ]

  return (
    <ExternalLineWrapper>
      <LineFields fields={line1} />
      <LineFields fields={line2} lineTitle="Рабочее место" />
      <LineFields fields={line3} />
      <LineFields fields={line4} />
      <LineFields fields={line5} />
      <LineFields fields={line6} />
      <LineFields fields={line7} />
      <LineFields fields={line8} lineTitle="Рекомендации" />
      <LineFields fields={line9} />
      <LineFields fields={line10} />
      <LineFields fields={line11} lineTitle="Численность работающих" />
      <LineFields fields={line12} />
    </ExternalLineWrapper>
  )
}

export default Main
