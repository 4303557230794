import styled from '@emotion/styled'

export const LinkWrapper = styled.div`
  display: flex;
  white-space: nowrap;
  padding-top: 13px;
  margin-left: 10px;
  align-items: center;
`

export const FiltersWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
`
