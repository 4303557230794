import { FC, ReactNode } from 'react'
import Text from '@organisms/Text'
import ConfirmModalContent from '@organisms/ConfirmModalContent'
import PrimaryButton from '@organisms/PrimaryButton'
import SecondaryButton from '@organisms/SecondaryButton'
import { ButtonsWrapper } from './styled'

type Props = {
  onClose?: () => void
  onAddMore?: () => void
  addTitle: string
  editTitle: string
  description: ReactNode
  isEdit: boolean
  withoutAddMore?: boolean
}

const SuccessContent: FC<Props> = ({
  onClose,
  onAddMore,
  addTitle,
  editTitle,
  description,
  isEdit,
  withoutAddMore = false,
}) => {
  return (
    <ConfirmModalContent
      leftIcon="success-bear-new.svg"
      title={isEdit ? editTitle : addTitle}
      rightContent={
        <>
          <Text>{description}</Text>
          <ButtonsWrapper>
            {!withoutAddMore && !isEdit && onAddMore && (
              <SecondaryButton onClick={onAddMore} big>
                Добавить еще
              </SecondaryButton>
            )}
            <PrimaryButton onClick={onClose} big>
              Закрыть
            </PrimaryButton>
          </ButtonsWrapper>
        </>
      }
    />
  )
}

export default SuccessContent
