import { FC, ReactNode } from 'react'
import Title from '@organisms/Title'
import FieldComponent from '@templates/Field'
import { Field } from '../index'
import { AllWrapper, LineWrapper, Wrapper } from './styled'

type Props = {
  fields: Field[]
  lineTitle?: string
  lineTitleFontSize?: number
  id?: string
  contentBefore?: ReactNode
  contentAfter?: ReactNode
}

const LineFields: FC<Props> = ({
  fields,
  lineTitle,
  lineTitleFontSize,
  id,
  contentBefore,
  contentAfter,
}) => (
  <AllWrapper id={id}>
    {contentBefore && <Wrapper>{contentBefore}</Wrapper>}
    {lineTitle && (
      <Wrapper>
        <Title style={lineTitleFontSize ? { fontSize: lineTitleFontSize } : {}}>
          {lineTitle}
        </Title>
      </Wrapper>
    )}
    <LineWrapper>
      {fields.map((item) => (
        <FieldComponent key={item.name} {...item} />
      ))}
    </LineWrapper>
    {contentAfter && <LineWrapper>{contentAfter}</LineWrapper>}
  </AllWrapper>
)

export default LineFields
