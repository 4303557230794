import { FC } from 'react'
import ListPageLayout, { ListPageLayoutProps } from '@templates/ListPageLayout'
import List from '../List'

type Props = {
  ItemComponent: FC<any> // correct
} & Omit<ListPageLayoutProps, 'ListComponent'>

const ListPage: FC<Props> = (props) => {
  return (
    <ListPageLayout
      {...props}
      listComponentProps={{ ItemComponent: props.ItemComponent }}
      ListComponent={List}
      scrollToElementId="top-header-scroll"
    />
  )
}

export default ListPage
