import styled from '@emotion/styled'

export const FileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: 18px;
`

export const TabWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`
