import { FC } from 'react'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import { Tab } from '@organisms/Tabs'
import RISK_MAP_STATUSES_CONFIG from './config'

type Props = {
  status: RiskMapStatuses
  index?: number
  isWhiteBackground?: boolean
}

const RiskMapStatus: FC<Props> = ({ status, isWhiteBackground, index }) => {
  const props = RISK_MAP_STATUSES_CONFIG[status]

  return (
    <Tab
      {...props}
      isWhiteBackground={isWhiteBackground}
      withoutHover
      isActive={false}
      id={`risk-map-status-${index}`}
    />
  )
}

export default RiskMapStatus
