import { FC } from 'react'
import useWorkPlaces from '@hooks/useWorkPlaces'
import WorkPlacesTable from '@pages/WorkPlaces/WorkPlacesTable'

const Sout: FC = () => {
  const { data } = useWorkPlaces()
  const { items = [], length = 0 } = data || {}

  return (
    <WorkPlacesTable
      list={items}
      length={length}
      disabledColumns={['position', 'division']}
    />
  )
}

export default Sout
