import { FC } from 'react'
import { Skeleton } from 'antd'
import Pagination from '@templates/Pagination'
import Title from '@organisms/Title'
import useWorkPlaces from '@hooks/useWorkPlaces'
import { Wrapper } from './styled'
import SoutItem from './SoutItem'

const SKELETON_HEIGHT = 70

const RiskMapsBodyList: FC = () => {
  const { data, setPage, isSuccess, isLoading } = useWorkPlaces({
    limit: 10,
  })
  const riskMaps = data?.items || []
  const itemsCount = data?.length || 0

  const handleChangePagination = (page: number) => {
    setPage?.(page)
  }

  if (isLoading) {
    return (
      <div>
        <Skeleton.Button
          active
          block
          style={{
            height: SKELETON_HEIGHT,
            marginBottom: 12,
            borderRadius: 30,
          }}
        />
      </div>
    )
  }

  if (isSuccess && itemsCount === 0) {
    return (
      <Wrapper>
        <Title mySize="h4">СОУТ не найдены</Title>
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      {riskMaps.map((item) => (
        <SoutItem key={item.id} item={item} />
      ))}
      <Pagination
        itemsCount={itemsCount}
        onChangePagination={handleChangePagination}
      />
    </Wrapper>
  )
}

export default RiskMapsBodyList
