import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import MaskedInput, { MaskedInputProps } from '@organisms/MaskedInput'
import { FieldForRender, FieldParams } from '..'

type Params = {
  placeholder: string
  isLoading?: boolean
  componentProps?: MaskedInputProps
} & FieldParams

const numberField = (params: Params): FieldForRender => {
  const {
    label,
    isLoading = false,
    placeholder,
    isRequired,
    componentProps: props,
  } = params

  const componentProps: MaskedInputProps = {
    ...props,
    maskOptions: {
      mask: Number,
    },
    placeholder,
    isLoading,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    Component: MaskedInput,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : { required: false }),
    },
    componentProps,
  }
}

export default numberField
