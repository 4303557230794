import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-y: auto;
  padding: 20px;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;

  ${() =>
    isMobile
      ? ''
      : `
    position: absolute;
    z-index: 150;
    right: 15px;
    top: -25px;
    height: 100vh;
  `}
`
