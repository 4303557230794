import { FC } from 'react'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { Field } from '@templates/AddNewItemModal/Content'
import { selectField } from '@templates/AddNewItemModal/templates'
import { Option } from '@organs/Select'
import { useGetJobConditionAssessmentsClassesQuery } from '@services/jobConditionAssesments'
import { ClassesFields } from '@modals/JobConditionAssessmentModal/enums'

const DROPDOWN_WIDTH = 350

const Classes: FC = () => {
  const { data } = useGetJobConditionAssessmentsClassesQuery()
  const classesOptions: Option[] = data?.items || []

  const line1: Field[] = [
    selectField({
      name: ClassesFields.CHEMICAL,
      label: 'Химический',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.BIOLOGICAL,
      label: 'Биологический',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.AEROSOLS,
      label: 'Аэрозоли преимущественно фиброгенного действия',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.NOISE,
      label: 'Шум',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  const line2: Field[] = [
    selectField({
      name: ClassesFields.INFRASOUND,
      label: 'Инфразвук',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.ULTRASOUND,
      label: 'Ультразвук воздушный',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.GENERAL_VIBRATION,
      label: 'Вибрация общая',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.LOCAL_VIBRATION,
      label: 'Вибрация локальная',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  const line3: Field[] = [
    selectField({
      name: ClassesFields.NON_ION_RADIATION,
      label: 'Неионизирующие излучения',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.ION_RADIATION,
      label: 'Ионизирующие излучения',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.MICROCLIMATE,
      label: 'Параметры микроклимата',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.LIGHT,
      label: 'Параметры световой среды',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  const line4: Field[] = [
    selectField({
      name: ClassesFields.LABOR_SEVERITY,
      label: 'Тяжесть трудового процесса',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.LABOR_TENSION,
      label: 'Напряженность трудового процесса',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
    selectField({
      name: ClassesFields.TOTAL_CLASS,
      label: 'Итоговый класс УТ',
      options: classesOptions,
      dropdownWidth: DROPDOWN_WIDTH,
    }),
  ]

  return (
    <ExternalLineWrapper>
      <LineFields fields={line1} />
      <LineFields fields={line2} />
      <LineFields fields={line3} />
      <LineFields fields={line4} />
    </ExternalLineWrapper>
  )
}

export default Classes
