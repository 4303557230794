import * as React from 'react'
import { FC, useEffect } from 'react'
import { useMount } from 'react-use'
import {
  useDeleteCompanyMutation,
  useGetCompanyQuery,
} from '@services/companies'
import { AccordionBodyProps } from '@organisms/Accordion'
import AccordionBodyField from '@organisms/AccordionBodyField'
import AccordionBodySelectedTabs from '@templates/AccordionBodySelectedTabs'
import EditModal from '@templates/EditModal'
import AccordionBodyFieldsEmployees from '@templates/AccordionBodyFieldsEmployees'
import CompanyModal from '@modals/CompanyModal'
import DeleteButton from '@templates/DeleteButton'
import useMe from '@hooks/useMe'
import FilialListItem from './FilialListItem'
import { FooterWrapper } from './styled'

type Props = { id: number } & AccordionBodyProps

const Body: FC<Props> = ({ onChangeSize, toggleLoading, id }) => {
  const { isAdmin } = useMe()

  const [deleteCompany, deleteData] = useDeleteCompanyMutation()
  const { data } = useGetCompanyQuery(id)
  const { fullTitle, companyBranches = [], curators } = data || {}

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [data?.id])

  if (!data) return null

  return (
    <div>
      <AccordionBodyField
        label="Полное наименование компании"
        value={fullTitle}
      />
      <AccordionBodyFieldsEmployees
        label="Кураторы компании"
        users={curators}
      />
      {companyBranches?.length > 0 && (
        <AccordionBodySelectedTabs
          onTabChange={onChangeSize}
          title="Филиалы"
          items={companyBranches.map((filial) => {
            const { title } = filial
            return {
              id: String(filial?.id),
              title,
              children: (
                <FilialListItem
                  key={filial?.id}
                  id={filial?.id}
                  onSuccess={onChangeSize}
                />
              ),
            }
          })}
        />
      )}
      <FooterWrapper>
        <EditModal
          ModalContent={CompanyModal}
          modalProps={{
            company: data,
          }}
        />
        <DeleteButton
          {...deleteData}
          onClick={() => deleteCompany(data.id)}
          disabled={!isAdmin}
          promptText={
            !isAdmin
              ? 'Для удаления обратитесь к администратору платформы через почту support@expschool.ru'
              : undefined
          }
        />
      </FooterWrapper>
    </div>
  )
}

export default Body
