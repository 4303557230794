import styled from '@emotion/styled'
import Title from '@atoms/Title'

export const Wrapper = styled(Title)<{
  isActive: boolean
}>`
  cursor: pointer;
  margin-right: 20px;
  color: ${({
    isActive,
    theme: {
      colors: {
        disable,
        text: { black },
      },
    },
  }) => (isActive ? black : disable)};
  margin-bottom: 15px;
`

export const SmallWrapper = styled.div<{
  selected: boolean
  isWhiteBackground: boolean
  withoutHover: boolean
}>`
  padding: 8px 12px;
  background: ${({ selected, theme, isWhiteBackground }) =>
    isWhiteBackground
      ? 'white'
      : selected
      ? theme.colors.main
      : theme.colors.background.primaryAccent};
  border-radius: 24px;
  display: inline-block;
  cursor: ${({ withoutHover }) => (withoutHover ? 'auto' : 'pointer')};
`

export const Text = styled.div<{
  selected: boolean
  isWhiteBackground: boolean
}>`
  font-size: ${({ theme }) => theme.text.h6.fontSize};
  line-height: ${({ theme }) => theme.text.h6.lineHeight};
  font-weight: 400;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${({ selected, theme, isWhiteBackground }) =>
    isWhiteBackground
      ? theme.colors.main
      : selected
      ? 'white'
      : theme.colors.main};
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;
`

export const SmallInnerWrapper = styled.div`
  display: flex;
  gap: 5px;
`
