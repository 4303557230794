import StoreTags from '@enums/StoreTags'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import {
  CreateRiskMapBody,
  CreateRiskMapFromFileBody,
  CreateRiskMapResponse,
  RiskMap,
  RiskMapDetailed,
  RiskMapDetailedServerResponse,
  RiskMapFilteredDanger,
  RiskMapOption,
  RiskSignificanceBody,
  RiskSignificanceResponse,
  UpdateRiskMapBody,
  UpdateRiskMapResponse,
} from '@models/riskMap'
import {
  createDownloadExcelReportQuery,
  downloadDocxFile,
  downloadExcelFile,
} from '@utils/files'
import {
  ACK_WITH_RISK_MAPS,
  ACK_WITH_RISK_MAPS_REPORT,
  DANGEROUS_REGISTRY,
  DANGEROUS_REGISTRY_REPORT,
  POSITIONS_WITHOUT_RISK_MAPS,
  POSITIONS_WITHOUT_RISK_MAPS_REPORT,
  RISK_MANAGEMENT_PLAN,
  RISK_MANAGEMENT_PLAN_REPORT,
  RISK_MANAGEMENT_SYSTEM,
  RISK_MANAGEMENT_SYSTEM_REPORT,
  SIZ,
} from '@const/reportNames'
import { Danger } from '@models/danger'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/risk_maps'

export const riskMapsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getRiskMaps: builder.query<
      { items: RiskMapOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (response: ItemsFromResponse<RiskMap>) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          label: item.number,
          value: item['@id'],
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.RISK_MAPS],
    }),
    createRiskMap: builder.mutation<CreateRiskMapResponse, CreateRiskMapBody>({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.RISK_MAPS,
              StoreTags.PROTECTION_MEANS,
              StoreTags.PROTECTION_MEAN,
            ]
          : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    createRiskMapFromFile: builder.mutation<void, CreateRiskMapFromFileBody>({
      invalidatesTags: (result) =>
        result ? [StoreTags.RISK_MAPS, StoreTags.POSITIONS] : [],
      query: (body) => ({
        url: `${BASE_URL}/upload_risk_map_xls`,
        method: 'POST',
        body,
      }),
    }),
    updateRiskMap: builder.mutation<UpdateRiskMapResponse, UpdateRiskMapBody>({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.RISK_MAPS,
              StoreTags.RISK_MAP,
              StoreTags.PROTECTION_MEANS,
              StoreTags.PROTECTION_MEAN,
            ]
          : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getRiskMapOld: builder.query<RiskMapDetailed, number>({
      providesTags: [StoreTags.RISK_MAP],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    getRiskMap: builder.query<RiskMapDetailed, number>({
      providesTags: [StoreTags.RISK_MAP],
      async queryFn(params, _queryApi, _extraOptions, fetchWithBQ) {
        const riskMapResponse = await fetchWithBQ({
          url: `${BASE_URL}/${params}`,
        })
        const riskMapServerResponse =
          (riskMapResponse.data as RiskMapDetailedServerResponse) || {}

        const dangersResponse = await fetchWithBQ({
          url: `/api/risk_map_dangers?riskMap=${riskMapServerResponse['@id']}`,
        })
        const dangersServerResponse =
          (dangersResponse.data as { [HYDRA_MEMBER]: Danger[] }) || {}

        return {
          data: {
            ...riskMapServerResponse,
            dangers: dangersServerResponse[HYDRA_MEMBER] || [],
          },
        }
      },
    }),
    sendForReview: builder.mutation<RiskMapDetailed, number>({
      invalidatesTags: [StoreTags.RISK_MAP, StoreTags.RISK_MAPS],
      query: (id) => ({
        url: `${BASE_URL}/${id}/send_for_review`,
        method: 'POST',
      }),
    }),
    approve: builder.mutation<
      RiskMapDetailed,
      { date: string | undefined; id: number }
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.RISK_MAPS, StoreTags.RISK_MAP] : [],
      query: ({ id, date }) => ({
        url: `${BASE_URL}/${id}/approve`,
        method: 'PUT',
        body: {
          date,
        },
      }),
    }),
    archive: builder.mutation<RiskMapDetailed, RiskMapDetailed>({
      invalidatesTags: [StoreTags.RISK_MAP, StoreTags.RISK_MAPS],
      query: ({ id }) => ({
        url: `${BASE_URL}/${id}/archive`,
        method: 'PUT',
        body: {},
      }),
    }),
    returnFromArchive: builder.mutation<RiskMapDetailed, RiskMapDetailed>({
      invalidatesTags: [StoreTags.RISK_MAP, StoreTags.RISK_MAPS],
      query: ({ id }) => ({
        url: `${BASE_URL}/${id}/return_from_archive`,
        method: 'PUT',
        body: {},
      }),
    }),
    calculateSignificance: builder.mutation<
      RiskSignificanceResponse,
      RiskSignificanceBody
    >({
      query: (body) => ({
        url: `${BASE_URL}/calculate_risk_significance`,
        method: 'POST',
        body,
      }),
    }),
    deleteRiskMap: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.RISK_MAPS,
        StoreTags.POSITION,
        StoreTags.EMPLOYEE,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
    getPositionWithoutRiskMapReport: builder.query<void, CommonQueryParams>({
      query: (body) => ({
        url: `${BASE_URL}/${POSITIONS_WITHOUT_RISK_MAPS_REPORT}`,
        responseHandler: downloadExcelFile(
          POSITIONS_WITHOUT_RISK_MAPS + '.xls'
        ),
        method: 'POST',
        body,
      }),
    }),
    getRiskMapControlMeasurePlanReport: builder.query<void, CommonQueryParams>({
      query: (body) => ({
        url: `${BASE_URL}/${RISK_MANAGEMENT_PLAN_REPORT}`,
        responseHandler: downloadExcelFile(RISK_MANAGEMENT_PLAN + '.xls'),
        method: 'POST',
        body,
      }),
    }),
    getRiskMapDangerReport: builder.query<void, CommonQueryParams>({
      query: (body) => ({
        url: `${BASE_URL}/${DANGEROUS_REGISTRY_REPORT}`,
        responseHandler: downloadExcelFile(DANGEROUS_REGISTRY + '.xls'),
        method: 'POST',
        body,
      }),
    }),
    getRiskMapEmployeeReviewReport: builder.query<void, CommonQueryParams>({
      query: (body) => ({
        url: `${BASE_URL}/${ACK_WITH_RISK_MAPS_REPORT}`,
        responseHandler: downloadExcelFile(ACK_WITH_RISK_MAPS + '.xls'),
        method: 'POST',
        body,
      }),
    }),
    getSizReport: builder.query<void, CommonQueryParams>({
      query: createDownloadExcelReportQuery(
        '/api/reports/risk_maps/risk_map_control_measure_plan',
        SIZ + '.xls'
      ),
    }),
    getRiskMapReport: builder.query<void, number>({
      query: (id) => ({
        url: `${BASE_URL}/${id}/risk_map_report`,
        responseHandler: downloadExcelFile(
          'Оценки профессиональных рисков.xls'
        ),
      }),
    }),
    getProfRiskManagementSystemReport: builder.query<void, CommonQueryParams>({
      query: (body) => ({
        url: `${BASE_URL}/${RISK_MANAGEMENT_SYSTEM_REPORT}`,
        responseHandler: downloadDocxFile(RISK_MANAGEMENT_SYSTEM + '.docx'),
        method: 'POST',
        body,
      }),
    }),
    getRiskMapsFilteredDangers: builder.query<
      {
        items: RiskMapFilteredDanger[]
        length: number
        params: CommonQueryParams
      },
      CommonQueryParams
    >({
      query: (params) => ({
        url: `${BASE_URL}/relations/dangers`,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<RiskMapFilteredDanger>,
        _,
        params
      ) => ({
        params,
        items: response[HYDRA_MEMBER],
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
    updateAllProtectionMeans: builder.mutation<
      void,
      { id: number; dateTime: string | undefined }
    >({
      invalidatesTags: (result) =>
        result ? [StoreTags.RISK_MAP, StoreTags.PROTECTION_MEANS] : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}/recalculate_protection_means`,
        method: 'POST',
        body,
      }),
    }),
  }),
})

export const {
  useGetRiskMapsQuery,
  useCreateRiskMapMutation,
  useUpdateRiskMapMutation,
  useLazyGetRiskMapQuery,
  useGetRiskMapQuery,
  useSendForReviewMutation,
  useApproveMutation,
  useArchiveMutation,
  useReturnFromArchiveMutation,
  useCalculateSignificanceMutation,
  useDeleteRiskMapMutation,
  useLazyGetPositionWithoutRiskMapReportQuery,
  useLazyGetRiskMapControlMeasurePlanReportQuery,
  useLazyGetRiskMapDangerReportQuery,
  useLazyGetRiskMapEmployeeReviewReportQuery,
  useLazyGetRiskMapReportQuery,
  useLazyGetProfRiskManagementSystemReportQuery,
  useCreateRiskMapFromFileMutation,
  useGetRiskMapsFilteredDangersQuery,
  useUpdateAllProtectionMeansMutation,
  useLazyGetSizReportQuery,
} = riskMapsApi
