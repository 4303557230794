import { FC, useEffect } from 'react'
import useFormProcessor from '@hooks/useFormProcessor'
import DownloadExampleButton from '@templates/DownloadExampleButton'
import AddNewItemModal from '@templates/AddNewItemModal'
import useMe from '@hooks/useMe'
import FileErrorComponent from '@templates/FileErrorComponent'
import { usePostEmployeesListMutation } from '@services/employees'
import { Option } from '@organisms/Select'
import { File } from '@models/file'
import useUploadManyEmployeesFields from './useUploadManyEmployeesFields'

type FormValues = {
  company: Option
  file: File
  filial: Option
}

type Props = {
  handleCloseModal: () => void
  onSave: (form: Record<string, unknown>) => void
}

const UploadManyEmployeesFile: FC<Props> = ({ handleCloseModal, onSave }) => {
  const { company, companyBranch, divisionCuratorWithOneDivision } = useMe()
  const templateLink = divisionCuratorWithOneDivision
    ? '/import_employees_for_education_template_for_curator_with_one_division.xls'
    : '/import_employees_for_education_template.xls'

  const methods = useFormProcessor()
  const fields = useUploadManyEmployeesFields(methods)

  const [postEmployeesLists, postData] = usePostEmployeesListMutation()
  const { data } = postData || {}

  const handleSubmit = (form: FormValues) => {
    void postEmployeesLists({
      file: form.file?.['@id'],
      company: form.company?.value || company['@id'],
      companyBranch: form.filial?.value || companyBranch['@id'],
      subdivision: undefined,
    })
  }

  useEffect(() => {
    if (data) {
      onSave(data)
      handleCloseModal()
    }
  }, [data])

  return (
    <AddNewItemModal
      {...postData}
      customFullTitle="Добавить сотрудников для обучения"
      contentBeforeFields={
        <DownloadExampleButton
          templateLink={templateLink}
          title="Школа экспертов — Шаблон импорта сотрудников.xls"
          description="Для сотрудников, которых нет на платформе, укажите обязательные поля: ФИО, один из идентификаторов: email, телефон или логин"
        />
      }
      methods={methods}
      onSubmitForm={handleSubmit}
      fields={fields}
      handleCloseModal={handleCloseModal}
      CustomErrorComponent={FileErrorComponent}
    />
  )
}

export default UploadManyEmployeesFile
