import { FC, memo, MouseEventHandler, useEffect } from 'react'
import { notification } from 'antd'
import Link, { LinkStyle } from '@organisms/Link'
import { DeleteOutlined } from '@ant-design/icons'
import StyledButton, { StyledButtonProps } from '@organisms/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'
import ButtonWithConfirm from '@templates/ButtonWithConfirm'

type Props = {
  deleteDescription?: string
  isLink?: boolean
  CustomComponent?: FC<{ onClick?: MouseEventHandler }>
  isSuccess?: boolean
  isError?: boolean
  reset?: () => void
  isLoading?: boolean
  promptText?: string
} & StyledButtonProps

const DeleteButton: FC<Props> = ({
  deleteDescription,
  isLink = false,
  CustomComponent,
  isError,
  isSuccess,
  isLoading,
  reset,
  children = 'Удалить',
  promptText,
  ...props
}) => {
  const Component: FC<{ onClick: MouseEventHandler }> = ({ onClick }) =>
    CustomComponent ? (
      <CustomComponent onClick={onClick} />
    ) : isLink ? (
      <Link
        onClick={onClick}
        text="Удалить"
        linkStyle={LinkStyle.DEFAULT}
        fontSize={10}
      />
    ) : (
      <StyledButton
        {...props}
        onClick={onClick}
        buttonStyle={ButtonStyle.GRAY}
        leftIcon={<DeleteOutlined />}
        isLoading={isLoading}
      >
        {children}
      </StyledButton>
    )

  const handleClickDelete: MouseEventHandler<HTMLButtonElement> = (e) => {
    props?.onClick?.(e)
  }

  useEffect(() => {
    if (isError) {
      notification.error({ message: 'Произошла ошибка при удалении' })
      reset?.()
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      notification.success({ message: 'Удаление прошло успешно' })
      reset?.()
    }
  }, [isSuccess])

  return (
    <ButtonWithConfirm
      ButtonComponent={Component}
      onConfirm={handleClickDelete}
      confirmText={deleteDescription || 'Вы уверены?'}
      confirmButtonText="Подтвердить"
      cancelText="Назад"
      promptText={promptText}
    />
  )
}

export default memo(DeleteButton)
