import { isMobile } from '@const/device'
import styled from '@emotion/styled'

export const RepeatDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 24px;
  width: ${({
    theme: {
      sizes: { inputWidth },
    },
  }) => (isMobile ? '85vw' : `${3 * 8 + 3 * inputWidth}px`)};
`

export const FieldWrapper = styled.div``
