import { FC } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import StyledButton, { StyledButtonProps } from '@organs/StyledButton'
import ButtonStyle from '@enums/ButtonStyle'

type Props = { buttonStyle?: ButtonStyle } & Omit<
  StyledButtonProps,
  'buttonStyle'
>

const AddButton: FC<Props> = ({
  buttonStyle = ButtonStyle.GRAY,
  children = 'Добавить',
  leftIcon = <PlusOutlined />,
  ...restProps
}) => {
  return (
    <StyledButton {...restProps} buttonStyle={buttonStyle} leftIcon={leftIcon}>
      {children}
    </StyledButton>
  )
}

export default AddButton
