import styled from '@emotion/styled'

export const DescriptionWrapper = styled.div`
  background-color: #f2f6fa;
  padding: 16px;
  border-radius: 24px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  max-width: 868px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`
