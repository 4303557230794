import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  padding: 15px 0;
`

export const TitleWrapper = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`

export const Container = styled.div<{ isLast: boolean }>`
  border-bottom: ${({ isLast }) => (isLast ? 'none' : '1.5px solid #C4C4C4')};
  padding-bottom: ${({ isLast }) => (isLast ? 'none' : '25px')};
`

export const SecondLine = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${() => (isMobile ? '100vw' : 'auto')};
  gap: 18px;
`

export const RiskSignificanceWrapper = styled.div`
  height: 32px;
  margin-top: -20px;
`
