import Tabs, { TabsProps } from '@organisms/Tabs'
import { FieldForRender, FieldParams } from '..'

type Params = {
  tabProps: TabsProps
  widthMultiplier?: number
} & FieldParams

const tabsField = (params: Params): FieldForRender => {
  const { tabProps, widthMultiplier = 1 } = params

  return {
    ...params,
    name: 'tabs',
    label: '',
    Component: Tabs,
    componentProps: {
      ...tabProps,
      withNoUnmount: true,
    },
    widthMultiplier,
  }
}

export default tabsField
