import styled from '@emotion/styled'

export const ContentWrapper = styled.div``

export const ContentBeforeItemsWrapper = styled.div`
  margin-bottom: 20px;
`

export const MultilineFieldWrapper = styled.div`
  padding: 0 30px;
`
