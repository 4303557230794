import { FC } from 'react'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import useFormProcessor from '@hooks/useFormProcessor'
import { DescriptionWrapper } from '@modals/EmployeeEducationModal/styled'

const WorkPlaceArchive: FC = () => {
  const methods = useFormProcessor()

  const handleSubmit = () => {
    alert('SUBMIT')
  }

  return (
    <AddNewItemModalLayout
      methods={methods}
      onSubmitForm={handleSubmit}
      customFullTitle="Архивировать просроченные карты"
      saveText="Архивировать"
    >
      <DescriptionWrapper>
        <b>Просроченных карт СОУТ: 16</b>
        Срок действия карты СОУТ: 5 лет.
        <br />
        Архивировать просроченные карты?
      </DescriptionWrapper>
    </AddNewItemModalLayout>
  )
}

export default WorkPlaceArchive
