import { FC } from 'react'
import { Tab } from '@organs/Tabs'

type Props = {
  index: number
  individualProtectionMeanExpires: boolean
  individualProtectionMeanExpired: boolean
  onClick: () => void
}

const SizExpire: FC<Props> = ({
  index,
  individualProtectionMeanExpires,
  individualProtectionMeanExpired,
  onClick,
}) => {
  if (!individualProtectionMeanExpires && !individualProtectionMeanExpired) {
    return null
  }

  return (
    <Tab
      onClick={onClick}
      title={individualProtectionMeanExpired ? 'СИЗ истек' : 'СИЗ истекает'}
      tabSize="small"
      badgeColor={individualProtectionMeanExpired ? 'red' : 'orange'}
      isActive={false}
      id={`risk-map-expire-${index}`}
    />
  )
}

export default SizExpire
