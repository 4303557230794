import { isBefore, parse } from 'date-fns'

export const isAfterTodayValidation = (value: any) => {
  if (!value) return true

  const date = parse(value, 'dd.MM.yyyy', new Date())

  return isBefore(date, new Date())
    ? 'Дата должна быть больше сегодняшней'
    : true
}

export const isBeforeTodayValidation = (value: any) => {
  if (!value) return true

  const date = parse(value, 'dd.MM.yyyy', new Date())

  return isBefore(date, new Date())
    ? undefined
    : 'Дата должна быть меньше сегодняшней'
}

export const hasValidationErrors = (
  errors: Record<string, unknown>,
  keys: string[]
): boolean => {
  const errorKeys = Object.keys(errors)

  return errorKeys.some((key) => keys.includes(key))
}
