export enum MainFields {
  CARD_NUMBER = 'cardNumber',
  DATE = 'date',
  FILE = 'file',
  COMPANY = 'company',
  FILIAL = 'filial',
  DIVISION = 'division',
  POSITION = 'position',
  WORK_PLACE_NUMBER = 'workplaceNumber',
  TITLE = 'title',
  CODE = 'code',
  SIMILAR_WORKPLACES_COUNT_AND_NUMBERS = 'similarWorkplacesCountAndNumbers',
  DECLARED = 'declared',
  EKTS_EDITION = 'etksEksEdition',
  USED_PRODUCTION_EQUIPMENT = 'usedProductionEquipment',
  USED_MATERIALS_AND_RAW = 'usedMaterialsAndRaw',
  WORK_IMPROVEMENT_RECOMENDATIONS = 'workConditionsImprovementRecommendations',
  WORK_REST_RECOMENDATIONS = 'workAndRestRegimeRecommendations',
  WORKER_APPLY_RECOMENDATIONS = 'employeeSelectionRecommendations',
  ON_WORK_PLACE = 'countOnWorkplace',
  ON_SIMILAR_WORK_PLACE = 'countOnSimilarWorkplaces',
  WOMEN = 'womenCount',
  UNDER_18 = 'under18Count',
  DISABLED = 'disabledCount',
}

export enum ClassesFields {
  CHEMICAL = 'chemicalClass',
  BIOLOGICAL = 'biologyClass',
  AEROSOLS = 'aerosolsFactor',
  NOISE = 'noiseFactor',
  INFRASOUND = 'infrasoundFactor',
  ULTRASOUND = 'ultrasoundAirFactor',
  GENERAL_VIBRATION = 'generalVibrationFactor',
  LOCAL_VIBRATION = 'localVibrationFactor',
  NON_ION_RADIATION = 'nonIonizingRadiationFactor',
  ION_RADIATION = 'ionizingRadiationFactor',
  MICROCLIMATE = 'microclimateParametersFactor',
  LIGHT = 'lightEnvironmentParametersFactor',
  LABOR_SEVERITY = 'laborProcessSeverityFactor',
  LABOR_TENSION = 'laborProcessTensionFactor',
  TOTAL_CLASS = 'workingConditionFinalClass',
}
