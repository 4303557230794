import * as React from 'react'
import { FC } from 'react'
import { useFormContext } from 'react-hook-form'
import { employeeField } from '@templates/AddNewItemModal/templates'
import MultilineFieldNew from '@templates/MultilineFieldNew'
import Title from '@organisms/Title'
import { CompanyOption } from '@models/company'
import useHasJobs from '@hooks/useHasJobs'
import useMe from '@hooks/useMe'

const RiskMemberMultilineFields: FC = () => {
  const { company: meCompany } = useMe()
  const { hasCompany } = useHasJobs()
  const { watch } = useFormContext()
  const company: CompanyOption | undefined = hasCompany
    ? watch('company')
    : meCompany

  const initValues = {
    company,
  }

  const commissionMembersField = employeeField({
    name: 'riskMapCommissionMembers',
    label: 'Член комиссии',
    isClearable: false,
    initValues,
    isDisabled: !company,
  })

  return (
    <div style={{ marginTop: 20 }}>
      <Title>Комиссия по оценке рисков</Title>
      <MultilineFieldNew
        fieldsSrc={[commissionMembersField]}
        fieldsName="riskMapCommissionMembers"
        amendValue={{}}
        addLabel="Добавить члена комиссии"
        isDisabled={!company}
      />
    </div>
  )
}

export default RiskMemberMultilineFields
