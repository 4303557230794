import { FC, ReactNode } from 'react'
import Prompt from '@organisms/Prompt'
import {
  FieldWrapper,
  InlineLabelWrapper,
  Label,
  LabelWrapper,
  PromptWrapper,
  RightLabel,
  Wrapper,
} from './styled'

type Props = {
  label: string
  field: ReactNode
  style?: Record<string, unknown>
  widthMultiplier?: number
  promptText?: ReactNode
  id?: string
  withRightLabel?: boolean
}

const FormField: FC<Props> = ({
  label,
  field,
  style,
  widthMultiplier = 1,
  promptText,
  withRightLabel = false,
  id,
}) => {
  const prompt = promptText && (
    <PromptWrapper>
      <Prompt promptText={promptText} width={500} />
    </PromptWrapper>
  )

  if (withRightLabel) {
    return (
      <InlineLabelWrapper widthMultiplier={widthMultiplier} id={id}>
        <div>{field}</div>
        {label && (
          <>
            <RightLabel>{label}</RightLabel>
            {prompt}
          </>
        )}
      </InlineLabelWrapper>
    )
  }

  return (
    <Wrapper id={id} style={style} widthMultiplier={widthMultiplier}>
      {label && (
        <LabelWrapper>
          <Label>{label}</Label>
          {prompt}
        </LabelWrapper>
      )}
      <FieldWrapper>{field}</FieldWrapper>
    </Wrapper>
  )
}

export default FormField
