import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  width: 100%;
  max-width: 800px;
  margin-top: 20px;
`

export const AllWrapper = styled.div``

const DesktopLineWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 18px;
`
const MobileLineWrapper = styled(DesktopLineWrapper)`
  flex-direction: column;
  align-items: flex-start;
`
export const LineWrapper = isMobile ? MobileLineWrapper : DesktopLineWrapper

export const ExternalLineWrapper = styled.div`
  margin: 10px 0 30px;
`
