import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import Select, { Option, SelectProps } from '@organisms/Select'
import { FieldForRender, FieldParams } from '..'

type Params = {
  options: Option[]
  isLoading?: boolean
  isMulti?: boolean
  withoutFetchOptions?: boolean
  length?: number
  dropdownWidth?: number
  componentProps?: SelectProps
  withManualEntryText?: boolean
} & FieldParams

const selectField = (params: Params): FieldForRender => {
  const {
    label,
    isRequired,
    options,
    isLoading,
    isMulti = false,
    widthMultiplier = 1,
    componentProps: props,
    length,
    isDisabled,
    dropdownWidth,
    withoutFetchOptions,
    name,
    withManualEntryText,
  } = params

  const componentProps: SelectProps = {
    ...props,
    placeholder: 'Выбрать из списка',
    options,
    isLoading,
    isMulti,
    length,
    isDisabled,
    dropdownWidth,
    withoutFetchOptions,
    menuPosition: 'fixed',
    withManualEntryText,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    Component: Select,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : { required: false }),
    },
    componentProps,
    widthMultiplier,
    id: name,
  }
}

export default selectField
