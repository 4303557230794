import {
  ClipboardEventHandler,
  DetailedHTMLProps,
  forwardRef,
  ForwardRefExoticComponent,
  InputHTMLAttributes,
  RefAttributes,
  useImperativeHandle,
  useRef,
} from 'react'
import { MaskedOptions } from 'imask'
import { StyledBigMaskedInput, StyledSmallMaskedInput } from './styled'

type Props = {
  big?: boolean
  hasError?: boolean
  maskOptions: MaskedOptions
  transformOnPaste?: (value: string) => string
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const MaskedInput: ForwardRefExoticComponent<
  Pick<
    Props,
    | 'transformOnPaste'
    | 'key'
    | 'big'
    | 'hasError'
    | 'maskOptions'
    | keyof InputHTMLAttributes<HTMLInputElement>
  > &
    RefAttributes<HTMLInputElement>
> = forwardRef(
  (
    { transformOnPaste, maskOptions, big = false, onChange, ...restProps },
    refProp
  ) => {
    const innerRef = useRef<HTMLInputElement | null>(null)
    useImperativeHandle(refProp, () => innerRef.current!, [])

    const handlePaste: ClipboardEventHandler = (event) => {
      event.preventDefault()
      const text = event.clipboardData.getData('text')?.toString()
      const transformedText = transformOnPaste ? transformOnPaste(text) : text
      const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
        window.HTMLInputElement.prototype,
        'value'
      )!.set
      nativeInputValueSetter!.call(innerRef.current!, transformedText)
      innerRef.current!.dispatchEvent(new Event('input', { bubbles: true }))
    }

    const Component = big ? StyledBigMaskedInput : StyledSmallMaskedInput

    return (
      <Component
        {...maskOptions}
        {...restProps}
        ref={refProp}
        inputRef={innerRef}
        onPaste={handlePaste}
        unmask={false}
        onAccept={(value: any) => onChange?.(value)}
      />
    )
  }
)

export type { Props as MaskedInputProps }
export default MaskedInput
