import { UseFormReturn } from 'react-hook-form'
import useJobFilters from '@hooks/useJobFilters'
import { FiltersProps } from '@templates/ListPageLayout/Filters'
import { FilterTypes } from '@templates/ListPageLayout/Filters/Filter'

// TODO
const useWorkPlacesFilters = (methods: UseFormReturn): FiltersProps => {
  const { company, filial, division, position } = useJobFilters(methods)

  const filters: FiltersProps = []

  if (company) {
    filters.push(company)
  }

  if (filial) {
    filters.push({
      ...filial,
      fieldNameForSubmit: 'companyBranch',
    })
  }

  if (division) {
    filters.push({
      ...division,
      fieldNameForSubmit: 'subdivision',
    })
  }

  if (position) {
    filters.push({
      ...position,
      fieldNameForSubmit: 'jobPosition',
    })
  }

  filters.push(
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'UT',
      placeholder: 'Итоговый класс УТ',
      options: [],
      withoutFetchOptions: true,
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'status',
      placeholder: 'Статус',
      options: [
        {
          value: 1,
          label: 'Все',
        },
        {
          value: 2,
          label: 'Активна',
        },
        {
          value: 3,
          label: 'Архив',
        },
      ],
      withoutFetchOptions: true,
    },
    {
      filterType: FilterTypes.SELECT,
      fieldName: 'date',
      placeholder: 'Срок действия',
      options: [
        {
          value: 1,
          label: 'Все',
        },
        {
          value: 2,
          label: 'Меньше 6 месяцев',
        },
        {
          value: 3,
          label: 'Меньше 3 месяцев',
        },
        {
          value: 4,
          label: 'Просрочено',
        },
      ],
      withoutFetchOptions: true,
      promptText: 'Срок действия карты 5 лет',
    },
    {
      filterType: FilterTypes.CHECKBOX,
      fieldName: 'check1',
      label: 'Не указана должность',
      valueOnChecked: 'false',
      promptText:
        'Для карт СОУТ нужно указывать должности из платформы, чтобы сотрудники могли увидеть эти карты',
    },
    {
      filterType: FilterTypes.CHECKBOX,
      fieldName: 'check2',
      label: 'Декларируемое рабочее место',
      valueOnChecked: 'false',
    }
  )

  return filters
}

export default useWorkPlacesFilters
