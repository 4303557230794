import { FC } from 'react'
import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import { BaseButtonProps } from '@organisms/BaseButton'
import { FieldForRender, FieldParams } from '..'

type Params = {
  componentProps?: BaseButtonProps
  Component: FC<any>
} & FieldParams

const modalField = (params: Params): FieldForRender => {
  const { label, isRequired, componentProps, Component } = params || {}

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : { required: false }),
    },
    Component,
    componentProps,
  }
}

export default modalField
