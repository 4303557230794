enum StoreTags {
  ME = 'ME',
  COURSES = 'COURSES',
  COURSE = 'COURSE',
  COURSE_CATEGORIES = 'COURSE_CATEGORIES',
  COURSE_CHAPTERS = 'COURSE_CHAPTERS',
  COURSE_CHAPTER = 'COURSE_CHAPTER',
  COURSE_THEMES = 'COURSE_THEMES',
  COURSE_THEME = 'COURSE_THEME',
  USER = 'USER',
  DOCUMENTS = 'DOCUMENTS',
  DOCUMENT = 'DOCUMENT',
  DOCUMENT_TYPES = 'DOCUMENT_TYPES',
  DOCUMENT_TYPE = 'DOCUMENT_TYPE',
  FILES = 'FILES',
  FILE = 'FILE',
  SCORMS = 'SCORMS',
  SCORM = 'SCORM',
  SETTINGS = 'SETTINGS',
  SETTING = 'SETTING',
  STATISTICS = 'STATISTICS',
  STATISTIC = 'STATISTIC',
  COMPANY = 'COMPANY',
  COMPANIES = 'COMPANIES',
  FILIAL = 'FILIAL',
  FILIALS = 'FILIALS',
  DIVISION = 'DIVISION',
  DIVISIONS = 'DIVISIONS',
  POSITION = 'POSITION',
  POSITIONS = 'POSITIONS',
  EMPLOYEE = 'EMPLOYEE',
  EMPLOYEES = 'EMPLOYEES',
  EMPLOYEE_EDUCATION = 'EMPLOYEE_EDUCATION',
  EMPLOYEE_EDUCATIONS = 'EMPLOYEE_EDUCATIONS',
  EMPLOYEE_EDUCATION_DESTINATION = 'EMPLOYEE_EDUCATION_DESTINATION',
  EMPLOYEE_EDUCATION_DESTINATIONS = 'EMPLOYEE_EDUCATION_DESTINATIONS',
  FILIAL_LIMIT_HISTORIES = 'FILIAL_LIMIT_HISTORIES',
  EMPLOYEE_EDUCATION_STATS = 'EMPLOYEE_EDUCATION_STATS',
  INSTRUCTIONS = 'INSTRUCTIONS',
  INSTRUCTION = 'INSTRUCTION',
  CERTIFICATES = 'CERTIFICATES',
  NOTIFICATIONS = 'NOTIFICATIONS',
  ONBOARDING = 'ONBOARDING',
  RISK_MAPS = 'RISK_MAPS',
  RISK_MAP = 'RISK_MAP',
  PROTECTION_MEANS = 'PROTECTION_MEANS',
  PROTECTION_MEAN = 'PROTECTION_MEAN',
  MEASURES_CONTENTS = 'MEASURES_CONTENTS',
  MEASURES_CONTENT = 'MEASURES_CONTENT',
  MEASURES_KINDS = 'MEASURES_KINDS',
  MEASURES_KIND = 'MEASURES_KIND',
  MEASURES = 'MEASURES',
  MEASURE = 'MEASURE',
  DANGER_EVENTS = 'DANGER_EVENTS',
  DANGER_EVENT = 'DANGER_EVENT',
  DANGER_KINDS = 'DANGER_KINDS',
  DANGER_KIND = 'DANGER_KIND',
  DANGER_OBJECTS = 'DANGER_OBJECTS',
  DANGER_OBJECT = 'DANGER_OBJECT',
  DANGER_WORK_PLACES = 'DANGER_WORK_PLACES',
  DANGER_WORK_PLACE = 'DANGER_WORK_PLACE',
  DANGERS = 'DANGERS',
  DANGER = 'DANGER',
  RISK_MAP_HISTORIES = 'RISK_MAP_HISTORIES',
  RISK_MAP_HISTORY = 'RISK_MAP_HISTORY',
  RISK_MAP_VERSIONS = 'RISK_MAP_VERSIONS',
  RISK_MAP_VERSION = 'RISK_MAP_VERSION',
  DANGER_HAZARDS = 'DANGER_HAZARDS',
  DANGER_HAZARD = 'DANGER_HAZARD',
  COMPANY_GROUPS = 'COMPANY_GROUPS',
  COMPANY_GROUP = 'COMPANY_GROUP',
  WORK_PLACES = 'WORK_PLACES',
  WORK_PLACE = 'WORK_PLACE',
  JOB_CONDITION_ASSESSMENTS = 'JOB_CONDITION_ASSESSMENTS',
  JOB_CONDITION_ASSESSMENT = 'JOB_CONDITION_ASSESSMENT',
}

export default StoreTags
