import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import { useMount } from 'react-use'
import { compact } from 'lodash'
import { AccordionBodyProps } from '@organisms/Accordion'
import Tabs, { TabsItem } from '@organisms/Tabs'
import { useGetRiskMapQuery } from '@services/riskMaps'
import Footer from '@pages/RiskMaps/RiskMap/Body/Footer'
import OnboardingCodes from '@enums/OnboardingCodes'
import Onboarding from '@templates/Onboarding'
import Sout from '@pages/RiskMaps/RiskMap/Body/Sout'
import ProtectionMeans from './ProtectionMeans'
import Main from './Main'
import Dangers from './Dangers'
import ActionLog from './ActionLog'
import VersionsHistory from './VersionsHistory'
import { getOnboardingSteps, ONBOARDING_TITLE } from './onboarding'

type Props = {
  id: number
  index: number
  initialActiveTab?: number
} & AccordionBodyProps

const Body: FC<Props> = ({
  onChangeSize,
  toggleLoading,
  id,
  index,
  initialActiveTab = 0,
}) => {
  const { data } = useGetRiskMapQuery(id)

  const [active, setActive] = useState(initialActiveTab)

  useMount(() => {
    toggleLoading(true)
  })

  useEffect(() => {
    if (data) {
      toggleLoading(false)
      onChangeSize()
    }
  }, [data?.id])

  if (!data) return null

  const tabs: TabsItem[] = compact([
    {
      title: 'Основные',
      id: `main-${index}`,
      children: (
        <Main onChangeSize={onChangeSize} riskMap={data} index={index} />
      ),
    },
    {
      title: 'Опасности и риски',
      id: `dangers-tab-${index}`,
      children: (
        <Dangers onChangeSize={onChangeSize} riskMap={data} index={index} />
      ),
    },
    {
      title: 'СИЗ',
      id: `protectionMeans-tab-${index}`,
      children: (
        <ProtectionMeans
          onChangeSize={onChangeSize}
          riskMap={data}
          index={index}
        />
      ),
    },
    window.ENABLE_SOUT && {
      title: 'СОУТ',
      id: `sout-${index}`,
      children: <Sout />,
    },
    {
      title: 'Лог действий',
      id: `action-log-tab-${index}`,
      children: (
        <ActionLog onChangeSize={onChangeSize} riskMap={data} index={index} />
      ),
    },
    {
      title: 'История версий',
      id: `versions-history-tab-${index}`,
      children: (
        <VersionsHistory
          onChangeSize={onChangeSize}
          riskMap={data}
          index={index}
        />
      ),
    },
  ])

  return (
    <div>
      <Onboarding
        firstStepTitle={ONBOARDING_TITLE}
        steps={getOnboardingSteps(index, setActive)}
        code={OnboardingCodes.risk_map_open_card}
        withScroll
      />
      <Tabs
        items={tabs}
        active={active}
        setActive={setActive}
        onTabChange={onChangeSize}
        tabSize="small"
        withoutMaxItems
      />
      <Footer data={data} index={index} />
    </div>
  )
}

export default Body
