import { FC } from 'react'
import Title from '@organisms/Title'
import Field from '@templates/Field'
import { numberField } from '@templates/AddNewItemModal/templates'
import { FieldWrapper, RepeatDescription } from './styled'

const repeatEducationField = numberField({
  placeholder: 'Повторное назначение',
  label: 'Повторно назначать раз в (месяцев)',
  name: 'repeatEducationAtMonth',
})

const RepeatEducationField: FC = () => {
  return (
    <div style={{ marginTop: 25 }} id="repeatEducationAtMonthBlock">
      <Title>Добавить повторные назначения</Title>
      <RepeatDescription>
        Выберите период, через который назначать повторные обучения. Важно:
        обучения будут созданы на всю группу, заданную в блоке «Кому назначить».
        Например, если было выбрано подразделение, и в него пришёл новый
        сотрудник, то ему тоже будет назначен курс.
      </RepeatDescription>
      <FieldWrapper>
        <Field {...repeatEducationField} />
      </FieldWrapper>
    </div>
  )
}

export default RepeatEducationField
