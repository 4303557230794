import * as React from 'react'
import { FC, memo, useContext, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import Title from '@organisms/Title'
import DeleteButton from '@templates/DeleteButton'
import LineFields from '@templates/AddNewItemModal/Content/LineFields'
import { selectField } from '@templates/AddNewItemModal/templates'
import FieldComponent from '@templates/Field'
import INJURY_POSSIBILITIES from '@const/injuryPossibilities'
import SEVERITY_DEGREES from '@const/severityDegrees'
import Modal from '@organisms/Modal'
import PrimaryButton from '@organisms/PrimaryButton'
import { useCalculateSignificanceMutation } from '@services/riskMaps'
import { Option } from '@organisms/Select'
import InjuryPossibilities from '@enums/InjuryPossibilities'
import SeverityDegrees from '@enums/SeverityDegrees'
import useHasJobs from '@hooks/useHasJobs'
import useMe from '@hooks/useMe'
import RiskSignificance from '@templates/RiskSignificance'
import { ExternalLineWrapper } from '@templates/AddNewItemModal/Content/LineFields/styled'
import { useAppSelector } from '@hooks/useAppSelector'
import { useAppDispatch } from '@hooks/useAppDispatch'
import {
  deleteSelectedMeresForms,
  deleteSelectedProtectionMeansForms,
  setMeresIsVisibleIndex,
  setProtectionMeansIsVisibleIndex,
} from '@services/riskMapModal'
import { memoEqual } from '@utils/memo'
import { useLazyGetDangersSearchedMeresQuery } from '@services/dangers'
import { useLazyGetDangersSearchedProtectionMeansQuery } from '@services/protectionMeans'
import { TopControllersContext } from '@modals/RiskMapModal/Dangers'
import {
  getDangerHazardName,
  getEventName,
  getHardGradeName,
  getProbabilityName,
} from '../../nameUtils'
import useCommonRiskMapFields from '../../useCommonRiskMapFields'
import MeresFields from './MeresFields'
import ProtectionMeansFields from './ProtectionMeansFields'
import {
  Container,
  SecondLine,
  TitleWrapper,
  Wrapper,
  RiskSignificanceWrapper,
} from './styled'

const probabilityField = (name: string) =>
  selectField({
    withoutFetchOptions: true,
    options: INJURY_POSSIBILITIES,
    label: 'Вероятность получения травмы',
    name,
  })

const hardGradeField = (name: string) =>
  selectField({
    withoutFetchOptions: true,
    options: SEVERITY_DEGREES,
    label: 'Степень тяжести',
    name,
  })

type Props = {
  id: string
  index: number
  remove: (index: number) => void
  isLast: boolean
}

const DangersFieldsBlock: FC<Props> = ({ id, index, remove, isLast }) => {
  const [
    getDangersSearchedMeres,
    { isLoading: getDangersSearchedMeresIsLoading },
  ] = useLazyGetDangersSearchedMeresQuery()
  const [
    getDangersSearchedProtectionMeansQuery,
    { isLoading: getDangersSearchedProtectionMeansQueryIsLoading },
  ] = useLazyGetDangersSearchedProtectionMeansQuery()

  const dispatch = useAppDispatch()

  const { enabledAutoAddSiz } = useContext(TopControllersContext)

  const meresIsVisibleIndex = useAppSelector(
    (state) => state.riskMapModal.meresIsVisibleIndex
  )
  const protectionMeansIsVisibleIndex = useAppSelector(
    (state) => state.riskMapModal.protectionMeansIsVisibleIndex
  )

  const meresState = useAppSelector(
    (state) => state.riskMapModal.selectedMeresForms[index]
  )

  const protectionMeansState = useAppSelector(
    (state) => state.riskMapModal.selectedProtectionMeansForms[index]
  )

  const { company: meCompany } = useMe()
  const { hasCompany } = useHasJobs()
  const methods = useFormContext()
  const { watch } = methods

  const [calculateSignificance, { data: calculateSignificanceData }] =
    useCalculateSignificanceMutation()

  const hazard = watch(getDangerHazardName(index))
  const event = watch(getEventName(index))
  const hardGrade: Option<SeverityDegrees> | undefined = watch(
    getHardGradeName(index)
  )
  const probability: Option<InjuryPossibilities> | undefined = watch(
    getProbabilityName(index)
  )
  const company = hasCompany ? watch('company') : meCompany

  useEffect(() => {
    if (hardGrade && probability) {
      void calculateSignificance({
        injuryPossibility: probability?.value,
        injurySeverity: hardGrade?.value,
      })
    }
  }, [hardGrade, probability])

  const openMeres = async () => {
    if (!meresState?.prefilled) {
      await getDangersSearchedMeres({
        hazard: hazard?.value,
        event: event?.value,
        modalIndex: index,
      })
    }

    setTimeout(() => {
      dispatch(setMeresIsVisibleIndex(index))
    }, 600)
  }

  const closeMeres = () => {
    dispatch(setMeresIsVisibleIndex(null))
  }

  const openProtectionMeans = async () => {
    if (enabledAutoAddSiz && !protectionMeansState?.prefilled) {
      await getDangersSearchedProtectionMeansQuery({
        event: event?.value,
        modalIndex: index,
      })
    }

    setTimeout(() => {
      dispatch(setProtectionMeansIsVisibleIndex(index))
    }, 600)
  }

  const closeProtectionMeans = () => {
    dispatch(setProtectionMeansIsVisibleIndex(null))
  }

  const {
    dangerWorkPlacesField,
    dangersObjects,
    dangerKindsField,
    dangerHazardsField,
    dangerEventsField,
  } = useCommonRiskMapFields(methods, { index })

  const handleDelete = () => {
    dispatch(deleteSelectedMeresForms(index))
    dispatch(deleteSelectedProtectionMeansForms(index))
    remove(index)
  }

  return (
    <Container isLast={isLast} id={`risk-map-danger-fields-${index}`}>
      <Wrapper key={id}>
        <TitleWrapper>
          <Title>Опасность</Title>
          <DeleteButton onClick={handleDelete} id="risk-map-danger-delete">
            Удалить опасность
          </DeleteButton>
          <PrimaryButton
            disabled={!hazard || !event}
            onClick={openMeres}
            id="risk-map-danger-fill-meres"
            isLoading={getDangersSearchedMeresIsLoading}
          >
            Заполнить меры
          </PrimaryButton>
          <PrimaryButton
            disabled={!event}
            onClick={openProtectionMeans}
            id="risk-map-danger-fill-protectionMean"
            isLoading={getDangersSearchedProtectionMeansQueryIsLoading}
          >
            Заполнить СИЗ
          </PrimaryButton>
        </TitleWrapper>
        <ExternalLineWrapper>
          <LineFields
            fields={[
              dangerWorkPlacesField(),
              dangersObjects(),
              dangerKindsField(),
              dangerHazardsField(),
            ]}
          />
        </ExternalLineWrapper>
        <SecondLine>
          {[
            dangerEventsField(),
            hardGradeField(getHardGradeName(index)),
            probabilityField(getProbabilityName(index)),
          ].map((item) => (
            <FieldComponent key={item.name} {...item} />
          ))}
          {calculateSignificanceData && (
            <FieldComponent
              label="Значимость риска"
              Component={() => (
                <RiskSignificanceWrapper>
                  <RiskSignificance children={calculateSignificanceData} />
                </RiskSignificanceWrapper>
              )}
              name="important"
            />
          )}
        </SecondLine>
      </Wrapper>
      <Modal visible={meresIsVisibleIndex === index} onModalClose={closeMeres}>
        <MeresFields
          hazard={hazard}
          event={event}
          dangerousIndex={index}
          onModalClose={closeMeres}
          company={company}
        />
      </Modal>
      <Modal
        visible={protectionMeansIsVisibleIndex === index}
        onModalClose={closeProtectionMeans}
      >
        <ProtectionMeansFields
          event={event}
          dangerousIndex={index}
          onModalClose={closeProtectionMeans}
        />
      </Modal>
    </Container>
  )
}

export default memo(DangersFieldsBlock, memoEqual)
