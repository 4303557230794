import { FC, useState } from 'react'
import { isEmpty, noop } from 'lodash'
import { useMount, useUnmount } from 'react-use'
import useFormProcessor from '@hooks/useFormProcessor'
import AddNewItemModalLayout from '@templates/AddNewItemModalLayout'
import Tabs, { TabsItem } from '@organisms/Tabs'
import { Option } from '@organisms/Select'
import {
  useCreateRiskMapMutation,
  useUpdateRiskMapMutation,
} from '@services/riskMaps'
import { CommonRiskMapBody, RiskMapDetailed } from '@models/riskMap'
import { File } from '@models/file'
import { PositionOption } from '@models/position'
import { DivisionOption } from '@models/division'
import { FilialOption } from '@models/filial'
import { CompanyOption } from '@models/company'
import RiskMapStatuses from '@enums/RiskMapStatuses'
import { useAppSelector } from '@hooks/useAppSelector'
import OnboardingCodes from '@enums/OnboardingCodes'
import Onboarding from '@templates/Onboarding'
import { getMultilineValue } from '@utils/form'
import { useAppDispatch } from '@hooks/useAppDispatch'
import { cleanAllForms } from '@services/riskMapModal'
import useSetDefaultProtectionMeans from '@modals/RiskMapModal/useSetDefaultProtectionMeans.'
import { AUTO_SIZ_NAME } from '@modals/RiskMapModalFromFile/useRiskMapFromFileFields'
import { hasValidationErrors } from '@utils/validation'
import useSetDefaultComissionMembers from './useSetDefaultComissionMembers'
import { TopTabsWrapper } from './styled'
import getDefaultValues from './getDefaultValues'
import MainRisks from './MainRisks'
import Dangers from './Dangers'
import useSetDefaultMeres from './useSetDefaultMeres'
import { getOnboardingSteps, ONBOARDING_TITLE } from './onboarding'

export type FormValues = {
  company: CompanyOption
  filial: FilialOption
  division: DivisionOption
  position: PositionOption
  file?: File | null
  number: string
  reviseBeforeAt?: string
  riskMapCommissionMembers?: Option[]
  autoAddProtectionMeans: boolean
  dangerDTOs: {
    id?: string
    meres: {
      mereKind?: Option
      content?: Option
      source?: string
      deadline?: string
      responsible?: Option
    }[]
    type?: Option
    kind?: Option
    event?: Option
    workPlace?: Option
    object?: Option
    hardGrade?: Option
    probability?: Option
    hazard?: Option
    selectedFromModal?: boolean
  }[]
}

type Props = {
  isEdit?: boolean
  handleCloseModal: () => void
  riskMap?: RiskMapDetailed
  initialActiveTopTab?: number
  onMount?: () => void
}

const RiskMapModal: FC<Props> = ({
  riskMap,
  isEdit = false,
  handleCloseModal,
  initialActiveTopTab = 0,
  onMount = noop,
}) => {
  useMount(onMount)
  const dispatch = useAppDispatch()
  const selectedMeresForms = useAppSelector(
    (state) => state.riskMapModal.selectedMeresForms
  )
  const selectedProtectionMeansForms = useAppSelector(
    (state) => state.riskMapModal.selectedProtectionMeansForms
  )
  const [active, setActive] = useState(initialActiveTopTab)

  const [create, createData] = useCreateRiskMapMutation()
  const [update, updateData] = useUpdateRiskMapMutation()
  const additionalProps = isEdit ? updateData : createData

  const methods = useFormProcessor({
    apiErrors: undefined,
    defaultValues: getDefaultValues(riskMap),
  })

  const autoAddProtectionMeans: boolean = methods.watch(AUTO_SIZ_NAME)

  useSetDefaultComissionMembers(methods, isEdit)
  useSetDefaultMeres(riskMap)
  useSetDefaultProtectionMeans(riskMap)

  const onModalClose = () => {
    dispatch(cleanAllForms())
    handleCloseModal()
  }

  useUnmount(onModalClose)

  const handleSubmitForm = (form: FormValues) => {
    const commonForm: CommonRiskMapBody = {
      number: form.number,
      jobPosition: form.position['@id'],
      reviseBeforeAt: form.reviseBeforeAt,
      file: form.file?.['@id'],
      autoAddProtectionMeans,
      commissionMembers: getMultilineValue(form.riskMapCommissionMembers),
      dangerDTOs: !isEmpty(form.dangerDTOs?.[0]?.workPlace)
        ? form.dangerDTOs?.map(
            (
              {
                event,
                kind,
                workPlace,
                object,
                hardGrade,
                probability,
                type,
                hazard,
              },
              index
            ) => {
              const meres = selectedMeresForms[index]?.meres || []
              const controlMeasures = isEmpty(meres[0])
                ? []
                : meres.map(
                    ({ mereKind, content, source, deadline, responsible }) => ({
                      kind: mereKind?.label,
                      content: content?.label,
                      fundingSource: source,
                      plannedCompletionDate: deadline,
                      responsiblePerson: responsible?.['@id'],
                    })
                  )

              const means =
                selectedProtectionMeansForms[index]?.protectionMeans || []

              const protectionMeans = isEmpty(means[0])
                ? []
                : means.map(
                    ({
                      type,
                      constructionMean,
                      quantity,
                      updateFrequency,
                      updateBeforeAt,
                      etnNumber,
                      additionalType,
                    }) => ({
                      type: type?.label,
                      etnNumber,
                      constructionMean: constructionMean?.label,
                      quantity: quantity ? +quantity : undefined,
                      updateFrequency: updateFrequency
                        ? +updateFrequency
                        : undefined,
                      updateBeforeAt: updateBeforeAt || undefined,
                      additionalProtectionMean: !!additionalType,
                    })
                  )

              return {
                hazard: hazard?.label,
                type: type?.value,
                event: event?.label,
                kind: kind?.label,
                object: object?.label,
                workPlace: workPlace?.label,
                injuryPossibility: probability?.value,
                injurySeverity: hardGrade?.value,
                controlMeasures,
                protectionMeans,
              }
            }
          )
        : [],
    }

    if (isEdit) {
      if (riskMap) {
        update({
          ...commonForm,
          id: riskMap.id,
        })
      }
    } else {
      create(commonForm)
    }
  }

  const topTabs: TabsItem[] = [
    {
      title: 'Основные',
      badgeColor: hasValidationErrors(methods.formState.errors, [
        'company',
        'filial',
        'division',
        'position',
        'number',
      ])
        ? 'red'
        : undefined,
      children: (
        <MainRisks
          notDraft={isEdit && riskMap?.status !== RiskMapStatuses.DRAFT}
        />
      ),
      id: '1',
    },
    {
      title: 'Опасности и риски',
      badgeColor: hasValidationErrors(methods.formState.errors, ['dangerDTOs'])
        ? 'red'
        : undefined,
      children: <Dangers />,
      id: 'dangers-tab',
    },
  ]

  return (
    <AddNewItemModalLayout
      {...additionalProps}
      handleCloseModal={onModalClose}
      onSubmitForm={handleSubmitForm}
      methods={methods}
      isEdit={isEdit}
      titlePostfix="карту рисков"
      successContentAddTitle="Карта рисков успешно добавлена"
      successContentEditTitle="Карта рисков успешно изменена"
      withStopPropagation
      onSaveAndAddMore={() => {
        dispatch(cleanAllForms())
      }}
    >
      {!isEdit && (
        <Onboarding
          firstStepTitle={ONBOARDING_TITLE}
          steps={getOnboardingSteps(() => {
            setActive(1)
          })}
          code={OnboardingCodes.risk_map_create}
          withScroll
          withFeedback
        />
      )}
      <TopTabsWrapper>
        <Tabs
          withNoUnmount
          items={topTabs}
          active={active}
          setActive={setActive}
          tabSize="small"
        />
      </TopTabsWrapper>
    </AddNewItemModalLayout>
  )
}

export default RiskMapModal
