import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  background-color: #f2f6fa;
  padding: ${() => (isMobile ? '16px' : '24px 48px')};
  border-radius: 24px;
  gap: 25px;
  align-items: center;
  width: fit-content;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
  font-family: Gilroy;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.1em;
  text-align: left;
  text-transform: uppercase;
`

export const Row = styled(Column)`
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: row;
`
