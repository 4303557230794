import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-end;
`

export const StyledSaveButtonWrapper = styled.div``

export const StyledExampleButtonWrapper = styled.div``
