import styled from '@emotion/styled'
import { isMobile } from '@const/device'

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`

const DesktopContent = styled.div<{
  width?: string
  withoutWhiteBackground: boolean
}>`
  position: relative;
  max-height: 95vh;
  max-width: 95vw;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 40vw;

  ${({ withoutWhiteBackground }) =>
    withoutWhiteBackground
      ? ''
      : 'background-color: white; min-height: 20vh; border-radius: 25px; padding: 25px;'}
  ${({ width }) => (width ? `width: ${width};` : '')}
`

const MobileContent = styled(DesktopContent)<{
  width?: string
  withoutWhiteBackground: boolean
}>`
  padding: 15px 5px;
  min-width: 90vw;
  max-height: 70vh;

  ${({ withoutWhiteBackground }) =>
    withoutWhiteBackground ? '' : 'min-height: 10vh;'}
`

export const ModalContent = isMobile ? MobileContent : DesktopContent

const DesktopCloseWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 20px;
  top: 20px;
`

const MobileCloseWrapper = styled(DesktopCloseWrapper)`
  margin: 0 10px 10px 0;
`

export const CloseWrapper = isMobile ? MobileCloseWrapper : DesktopCloseWrapper

const DesktopContentWrapper = styled.div``

const MobileContentWrapper = styled(DesktopContentWrapper)`
  margin-left: 10px;
`

export const ContentWrapper = isMobile
  ? MobileContentWrapper
  : DesktopContentWrapper
