import { Dispatch, SetStateAction } from 'react'
import { REQUIRED_VALIDATION_RULE } from '@const/validation'
import { FilterState } from '@hooks/useItemsFilters'
import Select, { Option, SelectProps } from '@organisms/Select'
import { FieldForRender, FieldParams } from '..'

type Params = {
  options: Option[]
  isLoading?: boolean
  isMulti?: boolean
  setFilters: Dispatch<SetStateAction<FilterState>>
  setPage: Dispatch<SetStateAction<number>>
  isDisabled?: boolean
  length: number
  dropdownWidth?: number
  componentProps?: SelectProps
  isClearable?: boolean
  withoutFetchOptions?: boolean
  withManualEntryText?: boolean
  withShowLabelInDescription?: boolean
} & FieldParams

const selectSearchField = (params: Params): FieldForRender => {
  const {
    label,
    isRequired,
    options,
    isLoading,
    isMulti = false,
    widthMultiplier = 1,
    componentProps: props,
    setFilters,
    isDisabled = false,
    length,
    setPage,
    dropdownWidth,
    isClearable,
    withoutFetchOptions,
    name,
    withManualEntryText = false,
    withShowLabelInDescription = false,
  } = params

  const componentProps: SelectProps = {
    ...props,
    setFilters,
    placeholderForManySelected: label,
    placeholder: withManualEntryText
      ? 'Вручную или начните поиск'
      : 'Начните ввод для поиска',
    options,
    isLoading,
    isMulti,
    isDisabled,
    length,
    setPage,
    dropdownWidth,
    menuPosition: 'fixed',
    isClearable,
    withoutFetchOptions,
    withManualEntryText,
  }

  return {
    ...params,
    label: isRequired ? `${label}*` : label,
    Component: Select,
    rules: {
      ...(isRequired ? REQUIRED_VALIDATION_RULE : { required: false }),
    },
    componentProps,
    widthMultiplier,
    id: name,
    descriptionPath: withShowLabelInDescription ? 'value.label' : undefined,
  }
}

export type { Params as SelectSearchFieldParams }
export default selectSearchField
