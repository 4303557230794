import StoreTags from '@enums/StoreTags'
import {
  JobConditionAssessment,
  JobConditionAssessmentDetailed,
  JobConditionAssessmentOption,
  CreateJobConditionAssessmentBody,
  CreateJobConditionAssessmentResponse,
  UpdateJobConditionAssessmentBody,
  UpdateJobConditionAssessmentResponse,
  CreateJobConditionAssessmentFromFileBody,
  JobConditionAssessmentClassOption,
  JobConditionAssessmentClass,
  JobConditionAssessmentWarrantyType,
  JobConditionAssessmentWarrantyTypeOption,
} from '@models/jobConditionAssessment'
import { CommonQueryParams, ItemsFromResponse } from '@models/common'
import { HYDRA_MEMBER, HYDRA_TOTAL_ITEMS } from '../../config/consts'
import emptySplitApi from '../api'

const BASE_URL = '/api/job_condition_assessments'

export const JobConditionAssessmentsApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    getJobConditionAssessments: builder.query<
      { items: JobConditionAssessmentOption[]; length: number },
      CommonQueryParams
    >({
      query: (params) => ({
        url: BASE_URL,
        params,
      }),
      transformResponse: (
        response: ItemsFromResponse<JobConditionAssessment>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.cardNumber,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
      providesTags: [StoreTags.JOB_CONDITION_ASSESSMENTS],
    }),
    createJobConditionAssessment: builder.mutation<
      CreateJobConditionAssessmentResponse,
      CreateJobConditionAssessmentBody
    >({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.WORK_PLACES,
              StoreTags.WORK_PLACE,
              StoreTags.JOB_CONDITION_ASSESSMENTS,
              StoreTags.JOB_CONDITION_ASSESSMENT,
            ]
          : [],
      query: (body) => ({
        url: BASE_URL,
        method: 'POST',
        body,
      }),
    }),
    updateJobConditionAssessment: builder.mutation<
      UpdateJobConditionAssessmentResponse,
      UpdateJobConditionAssessmentBody
    >({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.WORK_PLACES,
              StoreTags.WORK_PLACE,
              StoreTags.JOB_CONDITION_ASSESSMENTS,
              StoreTags.JOB_CONDITION_ASSESSMENT,
            ]
          : [],
      query: ({ id, ...body }) => ({
        url: `${BASE_URL}/${id}`,
        method: 'PUT',
        body,
      }),
    }),
    getJobConditionAssessment: builder.query<
      JobConditionAssessmentDetailed,
      number
    >({
      providesTags: [StoreTags.JOB_CONDITION_ASSESSMENT, StoreTags.WORK_PLACE],
      query: (id) => `${BASE_URL}/${id}`,
    }),
    deleteJobConditionAssessment: builder.mutation<void, number>({
      invalidatesTags: [
        StoreTags.WORK_PLACES,
        StoreTags.WORK_PLACE,
        StoreTags.JOB_CONDITION_ASSESSMENTS,
        StoreTags.JOB_CONDITION_ASSESSMENT,
      ],
      query: (id) => ({
        url: `${BASE_URL}/${id}`,
        method: 'DELETE',
      }),
    }),
    createJobConditionAssessmentsFromXls: builder.mutation<
      void,
      CreateJobConditionAssessmentFromFileBody
    >({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.WORK_PLACES,
              StoreTags.WORK_PLACE,
              StoreTags.JOB_CONDITION_ASSESSMENTS,
              StoreTags.JOB_CONDITION_ASSESSMENT,
            ]
          : [],
      query: (body) => ({
        url: `${BASE_URL}/xls`,
        method: 'POST',
        body,
      }),
    }),
    createJobConditionAssessmentsFromXml: builder.mutation<
      void,
      CreateJobConditionAssessmentFromFileBody
    >({
      invalidatesTags: (result) =>
        result
          ? [
              StoreTags.WORK_PLACES,
              StoreTags.WORK_PLACE,
              StoreTags.JOB_CONDITION_ASSESSMENTS,
              StoreTags.JOB_CONDITION_ASSESSMENT,
            ]
          : [],
      query: (body) => ({
        url: `${BASE_URL}/xml`,
        method: 'POST',
        body,
      }),
    }),
    getJobConditionAssessmentsClasses: builder.query<
      { items: JobConditionAssessmentClassOption[]; length: number },
      void
    >({
      query: () => ({
        url: '/api/job_condition_assessment_classes',
      }),
      transformResponse: (
        response: ItemsFromResponse<JobConditionAssessmentClass>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.value,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
    getJobConditionAssessmentsWarrantyTypes: builder.query<
      { items: JobConditionAssessmentWarrantyTypeOption[]; length: number },
      void
    >({
      query: () => ({
        url: '/api/job_condition_assessment_warranty_types',
      }),
      transformResponse: (
        response: ItemsFromResponse<JobConditionAssessmentWarrantyType>
      ) => ({
        items: response[HYDRA_MEMBER].map((item) => ({
          ...item,
          value: item['@id'],
          label: item.title,
        })),
        length: response[HYDRA_TOTAL_ITEMS],
      }),
    }),
  }),
})

export const {
  useGetJobConditionAssessmentsQuery,
  useCreateJobConditionAssessmentMutation,
  useUpdateJobConditionAssessmentMutation,
  useGetJobConditionAssessmentQuery,
  useLazyGetJobConditionAssessmentQuery,
  useDeleteJobConditionAssessmentMutation,
  useCreateJobConditionAssessmentsFromXlsMutation,
  useCreateJobConditionAssessmentsFromXmlMutation,
  useGetJobConditionAssessmentsClassesQuery,
  useGetJobConditionAssessmentsWarrantyTypesQuery,
} = JobConditionAssessmentsApi
