import {
  JobConditionAssessmentDetailed,
  JobConditionAssessmentWarrantyTypeOption,
} from '@models/jobConditionAssessment'
import { FormValues } from './index'

const getDefaultValues = (
  jobConditionAssessment: JobConditionAssessmentDetailed | undefined,
  jobConditionAssessmentWarranties: JobConditionAssessmentWarrantyTypeOption[]
): Partial<FormValues> => {
  const result: Partial<FormValues> = {
    jobConditionAssessmentWarranties: jobConditionAssessmentWarranties.map(
      ({ code }) => ({
        code,
        actual: false,
        required: false,
        reason: '',
      })
    ),
  }

  if (!jobConditionAssessment) return result

  return result
}

export default getDefaultValues
